import axios from 'axios';
import authService from '../auth/authService';

export const callAuthApi = async (url: string, method: 'get' | 'post' | 'delete' | 'put', data?: object) => {
    let bearer: string = "Bearer " + await authService.getApiToken();

    const headers = { 'Authorization': bearer }

    switch (method) {
        case 'get':
            return axios.get(url, { headers: headers });
        case 'post':
            return axios.post(url, { headers: headers });
        case 'put':
            return axios.put(url, data, { headers: headers });
        case 'delete':
            return axios.delete(url, { headers: headers });
    }
}

export const callApi = async (url: string) => {
    return axios.get(url);
}
