import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthRequiredPage from "app/auth/components/AuthRequiredPage";
import Requests from "./Requests/Requests";
import RequestEdit from "./Requests/RequestEdit";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/requests" />
      </Route>
      <Route path="/requests" exact>
        <AuthRequiredPage>
          <Requests />
        </AuthRequiredPage>
      </Route>
      <Route path="/requests/:requestCode/:activeRole">
        <AuthRequiredPage>
          <RequestEdit />
        </AuthRequiredPage>
      </Route>
      <Route>
        <Redirect to="/requests" />
      </Route>
    </Switch>
  );
};

export default Routes;
