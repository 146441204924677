import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store, { appInitAction } from "./store";
import Routes from "./routes/Routes";
import { FatalError } from "./routes/Errors";
import * as ABB from "@abb/abb-common-ux-react";

const App = () => {
  const toHome = () => {
    return (window.location.pathname = "/");
  };

  return (
    <FatalError>
      <Provider store={store}>
        <Router>
          <ABB.AppContainer theme="light" style={{ width: "100%" }}>
            <ABB.AbbBar productName="EPiC - RAISE" />
            <ABB.AppHeader>
              <ABB.AppTopNavi>
                <ABB.AppTopNaviItem
                  active={true}
                  onClick={() => toHome()}
                  icon="abb/home"
                />
              </ABB.AppTopNavi>
              <ABB.AppHeaderSpacer />
              <ABB.AppTopNavi>
                <ABB.AppTopNaviItem
                  text=""
                  /*onClick={() => this.toggleUserMenu()}*/ hasMenu={false}
                ></ABB.AppTopNaviItem>
              </ABB.AppTopNavi>
            </ABB.AppHeader>

            <ABB.AppContent
              style={{
                height: "calc(100vh - 22px)",
              }}
            >
              <ABB.AppMainContent>
                <div
                  className="container fullHeight no-gutters"
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(100% - 62px)",
                    height: "calc(100% - 62px)",
                    padding: "20px",
                    margin: "8px auto",
                    overflowY: "auto",
                  }}
                >
                  <Routes />
                </div>
                <ABB.AppFooter showCopyright={true} showLogo={false} />
              </ABB.AppMainContent>
            </ABB.AppContent>
          </ABB.AppContainer>
        </Router>
      </Provider>
    </FatalError>
  );
};

store.dispatch(appInitAction());

export default App;
