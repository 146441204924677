import { all } from 'redux-saga/effects';
import auth from '../auth/authSaga';
import requests from '../requests/requestsSaga';
import user from '../user/userSaga';

export const API_BASE_URL = process.env.REACT_APP_API_URL
export const REQUEST_BASE_URL = `${API_BASE_URL}/request`

//main saga event loop
export default function* appSaga() {
    yield all([
        auth(),
        requests(),
        user(),
    ])
}