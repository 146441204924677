import * as ABB from "@abb/abb-common-ux-react";
import {
  completeRequest,
  deleteRequest,
  getRequests,
  getRequestsError,
  getRequestsSuccess,
} from "app/requests/requestsSlice";
import { callAuthApi } from "app/services/api";
import { RootState } from "app/store";
import { REQUEST_BASE_URL } from "app/store/appSaga";
import { getUser, setActiveRole } from "app/user/userSlice";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const isBlank = (str: string) => {
  return (
    str === null ||
    str === undefined ||
    (typeof str === "string" && /^\s*$/.test(str))
  );
};

const initialColumns: Array<ABB.DatagridColumnDef> = [
  {
    fieldKey: "request_code",
    title: "Request Code",
    width: 140,
  },
  {
    fieldKey: "requestor",
    title: "Requestor",
    width: 230,
  },
  {
    fieldKey: "user_preferred_datetime",
    title: "Datetime",
    width: 110,
    sortFn: (a, b) => {
      const dateA = new Date(a.key);
      const dateB = new Date(b.key);
      if (dateA < dateB) return -1;
      else if (dateA > dateB) return 1;
      else return 0;
    },
  },
  {
    fieldKey: "assistance_type",
    title: "Assistance Type",
    width: 230,
  },
  {
    fieldKey: "state",
    title: "State",
    width: 120,
  },
  {
    fieldKey: "country",
    title: "Country",
    width: 110,
  },
  {
    fieldKey: "actions",
    title: "Actions",
    width: 112,
    sortable: false,
  },
];

const Requests = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const requestsState = useSelector((state: RootState) => state.requests);
  const userState = useSelector((state: RootState) => state.user);
  const activeRole = userState.user.active_role;
  const [columnFilters, setColumnFilters] = useState({
    request_code: { value: "", placeholder: "Request code" },
    requestor: { value: "", placeholder: "Requestor" },
    state: { value: "", placeholder: "State" },
    country: { value: "", placeholder: "Country" },
  });
  const [initialList, setInitialList] = useState<ABB.DatagridRow[]>();
  const [sortOrder, setSortOrder] = useState<{
    field: string;
    desc: boolean;
  }>({
    field: "user_preferred_datetime",
    desc: false,
  });
  const [getCanceled, setGetCanceled] = useState<boolean>(false);

  const getRequestsList = useCallback(() => {
    setInitialList([]);
    dispatch(getRequests());
    callAuthApi(`${REQUEST_BASE_URL}?get_canceled=${getCanceled}`, "get")
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(getRequestsSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(getRequestsError(error));
      });
  }, [dispatch, getCanceled]);

  const editLine = useCallback(
    (requestCode: string) => {
      history.push(`/requests/${requestCode}/${activeRole}`);
    },
    [activeRole, history]
  );

  const rows: ABB.DatagridRow[] = useMemo(() => {
    if (
      requestsState &&
      ((activeRole === "service_expert" &&
        requestsState.service_expert_requests.length) ||
        (activeRole === "admin" && requestsState.admin_requests.length))
    ) {
      const reqs =
        activeRole === "service_expert"
          ? requestsState.service_expert_requests
          : requestsState.admin_requests;

      const requests = reqs.map((request) => {
        return {
          fields: {
            request_code: request.request_code,
            requestor: request.requestor,
            user_preferred_datetime: (
              <div key={request.user_preferred_datetime}>
                {moment(request.user_preferred_datetime)
                  .format("DD-MM-YYYY hh:mm:ss")
                  .toString()}
              </div>
            ),
            country: request.country.code,
            assistance_type: request.assistance_type.code.replaceAll("_", " "),
            state: request.state.code,
            actions: (
              <Fragment>
                <ABB.WithTooltip>
                  <ABB.Icon
                    name="abb/edit"
                    sizeClass="medium"
                    color="black"
                    style={{ cursor: "pointer" }}
                    onClick={() => editLine(request.request_code)}
                  />
                  <ABB.Tooltip>Edit request</ABB.Tooltip>
                </ABB.WithTooltip>
                {activeRole === "admin" && request.state.code !== "CANCELED" && (
                  <ABB.WithDialog>
                    <ABB.Icon
                      name="abb/trash"
                      sizeClass="medium"
                      color="red"
                      style={{ cursor: "pointer" }}
                    />
                    <ABB.Dialog
                      showCloseButton={true}
                      closeOnEscape={true}
                      closeOnLostFocus={true}
                      dimBackground={true}
                      title="Delete request"
                      standardButtonsOnBottom={[
                        {
                          text: "No",
                          type: "discreet-blue",
                          handler: (dlg) => dlg.close(),
                        },
                        {
                          text: "Yes",
                          type: "primary-blue",
                          handler: (dlg) => {
                            dlg.close();
                            dispatch(deleteRequest(request.request_code));
                          },
                        },
                      ]}
                    >
                      Are you sure you want to delete this request?
                    </ABB.Dialog>
                  </ABB.WithDialog>
                )}
                {request.state.code === "PLANNED" && (
                  <ABB.WithDialog>
                    <ABB.Icon
                      name="abb/check-mark"
                      sizeClass="medium"
                      color="green"
                      style={{ cursor: "pointer" }}
                    />
                    <ABB.Dialog
                      showCloseButton={true}
                      closeOnEscape={true}
                      closeOnLostFocus={true}
                      dimBackground={true}
                      title="Complete request"
                      standardButtonsOnBottom={[
                        {
                          text: "No",
                          type: "discreet-blue",
                          handler: (dlg) => dlg.close(),
                        },
                        {
                          text: "Yes",
                          type: "primary-blue",
                          handler: (dlg) => {
                            dlg.close();
                            dispatch(completeRequest(request.request_code));
                          },
                        },
                      ]}
                    >
                      Are you sure you want to mark this request as completed?
                    </ABB.Dialog>
                  </ABB.WithDialog>
                )}
              </Fragment>
            ),
          },
        };
      });
      setInitialList(requests);
      return requests;
    }
    setInitialList([]);
    return [];
  }, [activeRole, dispatch, editLine, requestsState]);

  const handleColumnFilterChange = useCallback(
    (newFilters: any) => {
      setColumnFilters(newFilters);
      const filteredData = rows
        ? rows
            .map((c: any) => Object.assign({}, c))
            .filter((c: any) => {
              return (
                (isBlank(newFilters.request_code.value) ||
                  c.fields.request_code
                    .toLowerCase()
                    .indexOf(newFilters.request_code.value.toLowerCase()) >
                    -1) &&
                (isBlank(newFilters.requestor.value) ||
                  c.fields.requestor
                    .toLowerCase()
                    .indexOf(newFilters.requestor.value.toLowerCase()) > -1) &&
                (isBlank(newFilters.state.value) ||
                  c.fields.state
                    .toLowerCase()
                    .indexOf(newFilters.state.value.toLowerCase()) > -1) &&
                (isBlank(newFilters.country.value) ||
                  c.fields.country
                    .toLowerCase()
                    .indexOf(newFilters.country.value.toLowerCase()) > -1)
              );
            })
        : [];
      setInitialList(filteredData);
    },
    [rows]
  );

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    const user = userState.user;
    if (user && user.user_email) {
      getRequestsList();
    }
  }, [getRequestsList, userState.user]);

  useEffect(() => {
    if (requestsState.deleteCompleted === true) {
      getRequestsList();
    }
  }, [getRequestsList, requestsState.deleteCompleted]);

  useEffect(() => {
    if (requestsState.completingDone === true) {
      getRequestsList();
    }
  }, [getRequestsList, requestsState.completingDone]);

  if (userState.error) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="alert alert-danger mt-2" role="alert">
            {userState.error}
          </div>
        </div>
      </div>
    );
  }

  if (userState.isNotAuthorized === true) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="alert alert-danger mt-2 text-center" role="alert">
            <p>You don't have access here. </p>
            <p>
              Please contact{" "}
              <a href="mailto:roberto.conca@it.abb.com">Roberto Conca</a> to ask
              for access.
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!requestsState.requestsLoaded || userState.isLoading) {
    return (
      <ABB.LoadingIndicator
        type="bar"
        sizeClass="medium"
        color="red"
        determinate={false}
      />
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <ABB.Checkbox
          label={"Get canceled requests"}
          value={getCanceled}
          onChange={() => setGetCanceled(!getCanceled)}
        />
        {userState.user.is_admin && userState.user.is_service_expert && (
          <ABB.Dropdown
            style={{
              width: "140px",
            }}
            value={[
              {
                value: activeRole,
              },
            ]}
            onChange={(val) => {
              if (val && val.length) {
                dispatch(setActiveRole(val[0].value));
              }
            }}
          >
            <ABB.DropdownOption
              key="service_expert"
              label="Service Expert"
              value="service_expert"
            />
            <ABB.DropdownOption key="admin" label="Admin" value="admin" />
          </ABB.Dropdown>
        )}
      </div>

      <ABB.Datagrid
        data={initialList}
        columns={initialColumns}
        rowHeight="small"
        zebraColoring={true}
        enableSorting={true}
        enableRowExpansion={false}
        enableRowSelection={false}
        enableColumnDragging={false}
        __IN_PROGRESS__enableColumnResize={true}
        sortOrder={sortOrder}
        onSort={(so) => setSortOrder(so)}
        columnFilters={columnFilters}
        onColumnFiltersChange={handleColumnFilterChange}
        fitToContent={true}
      />
    </div>
  );
};

export default Requests;
