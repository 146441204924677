import React, { Component, ErrorInfo, PropsWithChildren } from "react";

export function PageNotFound() {
	return (
		<div className="splash-screen">
			<div className="error-container-full-page">
				<h1 className="error-code">404</h1>
				<p>La pagina che stai cercando non è stata trovata</p>
				<p>
					<small className="text-muted">
						Verifica che l'indirizzo sia corretto
					</small>
				</p>
			</div>
		</div>
	);
}

export class FatalError extends Component<
	PropsWithChildren<{}>,
	{ hasError: boolean; errorMessage?: string }
> {
	constructor(props: PropsWithChildren<{}>) {
		super(props);
		this.state = {
			hasError: false,
			errorMessage: undefined,
		};
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true, errorMessage: error.message };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

	render() {
        if(this.state.hasError)
		return (
			<div className="splash-screen">
				<div className="error-container-full-page">
					<h1 className="error-code">Ops...</h1>
					<p>Si è verificato un errore, riprova più tardi</p>
					<p>
						<small className="text-muted">
							{this.state.errorMessage}
						</small>
					</p>
				</div>
			</div>
        );
        else
        return (<>{this.props.children}</>)
	}
}
