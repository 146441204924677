import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Label {
  de?: string;
  en?: string;
  es?: string;
  fr?: string;
  it?: string;
  zh?: string;
}

export interface DbResponse {
  code: string;
  label: Label;
}

export interface ExtendedDbResponse extends DbResponse {
  description: Label;
}

export interface ServiceExpert {
  user_email: string;
  acty_link: string;
}

export interface RAISERequestIn {
  request_code: string;
  requestor: string;
  requestor_phone: string;
  user_preferred_datetime: string;
  product_type: string;
  country: string;
  assistance_type: string;
  device: string;
  time_slot: string | undefined;
  note: string | undefined;
  state: string;
  paid: string;
  service_expert_email: string | undefined;
  created_datetime: string;
  modified_datetime: string | undefined;
}

export interface RAISERequest {
  request_code: string;
  requestor: string;
  requestor_phone: string;
  user_preferred_datetime: string;
  product_type: DbResponse;
  country: DbResponse;
  assistance_type: ExtendedDbResponse;
  device: DbResponse;
  time_slot: DbResponse;
  note: string;
  state: DbResponse;
  paid: string;
  service_expert?: ServiceExpert;
  created_datetime: string;
  modified_datetime: string;
}

interface RAISERequests {
  service_expert_requests: Array<RAISERequest>;
  admin_requests: Array<RAISERequest>;
}

export interface RaiseRequestEdit {
  request_code: string;
  user_preferred_datetime: string;
  note: string | undefined;
  paid: string;
  service_expert_email: string | undefined;
}

interface RequestSliceState extends RAISERequests {
  isLoading: boolean;
  requestsLoaded: boolean;
  isDeleting: boolean;
  deleteCompleted: boolean;
  isUpdating: boolean;
  isCompleting: boolean;
  completingDone: boolean;
  singleRequest?: RAISERequest;
  error?: string;
  isLoadingAsyncData: boolean;
}

const initState: RequestSliceState = {
  isLoading: false,
  requestsLoaded: false,
  isDeleting: false,
  deleteCompleted: false,
  isUpdating: false,
  isCompleting: false,
  completingDone: false,
  service_expert_requests: [],
  admin_requests: [],
  singleRequest: undefined,
  error: undefined,
  isLoadingAsyncData: false,
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState: initState,
  reducers: {
    getRequests: (state) => {
      state.isLoading = true;
      state.service_expert_requests = [];
      state.admin_requests = [];
      state.requestsLoaded = false;
    },
    getRequestsSuccess: (state, action: PayloadAction<RAISERequests>) => {
      state.isLoading = false;
      state.service_expert_requests = action.payload.service_expert_requests;
      state.admin_requests = action.payload.admin_requests;
      state.requestsLoaded = true;
    },
    getRequestsError: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.service_expert_requests = [];
      state.admin_requests = [];
      state.requestsLoaded = true;
      state.error = action.payload.message;
    },
    getSingleRequest: (
      state,
      action: PayloadAction<{ requestCode: string; activeRole: string }>
    ) => {
      state.error = undefined;
      state.isLoading = true;
      state.singleRequest = undefined;
    },
    getSingleRequestSuccess: (state, action: PayloadAction<RAISERequest>) => {
      state.isLoading = false;
      state.singleRequest = action.payload;
    },
    updateRequest: (state) => {
      state.requestsLoaded = false;
      state.isUpdating = true;
    },
    updateRequestSuccess: (state) => {
      state.isUpdating = false;
    },
    updateRequestError: (state, action: PayloadAction<string>) => {
      state.isUpdating = false;
      state.error = action.payload;
    },
    deleteRequest: (state, action: PayloadAction<string>) => {
      state.isDeleting = true;
    },
    deleteRequestSuccess: (state) => {
      state.isDeleting = false;
      state.deleteCompleted = true;
    },
    deleteRequestError: (state, action: PayloadAction<Error>) => {
      state.isDeleting = false;
      state.deleteCompleted = false;
      state.error = action.payload.message;
    },
    completeRequest: (state, action: PayloadAction<string>) => {
      state.isCompleting = true;
    },
    completeRequestSuccess: (state) => {
      state.isCompleting = false;
      state.completingDone = true;
    },
    completeRequestError: (state, action: PayloadAction<Error>) => {
      state.isCompleting = false;
      state.completingDone = false;
      state.error = action.payload.message;
    },
    getCountries: (state) => {
      state.isLoadingAsyncData = true;
    },
    getCountriesSuccess: (state) => {
      state.isLoadingAsyncData = false;
    },
    getCountriesError: (state, action: PayloadAction<Error>) => {
      state.isLoadingAsyncData = false;
      state.error = action.payload.message;
    },
    getServiceExperts: (state) => {
      state.isLoadingAsyncData = true;
    },
    getServiceExpertsSuccess: (state) => {
      state.isLoadingAsyncData = false;
    },
    getServiceExpertsError: (state, action: PayloadAction<Error>) => {
      state.isLoadingAsyncData = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  getRequests,
  getSingleRequest,
  getRequestsSuccess,
  getSingleRequestSuccess,
  getRequestsError,
  updateRequest,
  updateRequestSuccess,
  updateRequestError,
  deleteRequest,
  deleteRequestSuccess,
  deleteRequestError,
  completeRequest,
  completeRequestSuccess,
  completeRequestError,
  getCountries,
  getCountriesSuccess,
  getCountriesError,
  getServiceExperts,
  getServiceExpertsSuccess,
  getServiceExpertsError,
} = requestsSlice.actions;
export default requestsSlice.reducer;
