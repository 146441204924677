import * as msal from "@azure/msal-browser";
import * as Bowser from "bowser";

//view https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md

export interface AuthConfiguration {
    clientId: string,
    //redirectUri: string,
    //postRedirectUri: string,
    autorities: {
        login: string,
        [key: string]: string
    }
    scopes: { [key: string]: string }
}

class AuthNotInitError extends Error {
    constructor() {
        super("AuthService: this service has not been initialized! Be sure to call init method before start using this service")
    }
}

class AuthService {
    protected isInit = false;

    private STORAGE_KEY: string = "Auth_Hint";
    private msalConfig?: msal.Configuration
    protected client?: msal.PublicClientApplication

    protected _account?: msal.AccountInfo
    protected configuration?: AuthConfiguration;

    private browser = Bowser.getParser(window.navigator.userAgent);
    protected isIE = this.browser.satisfies({ ie: '<12' });
    //protected isIE = true

    constructor(configuration?: AuthConfiguration) {
        if (configuration) {
            this.init(configuration);
        }
    }

    public init(configuration: AuthConfiguration) {
        this.configuration = configuration
        const loginAuthority: string = "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd/"
        this.msalConfig = {
            auth: {
                clientId: configuration.clientId,
                authority: loginAuthority,
                knownAuthorities: ["login.microsoftonline.com"],
            },
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: true
            }
        }

        this.client = new msal.PublicClientApplication(this.msalConfig);
        this.isInit = true;
    }

    async login() {
        let options = {
            scopes: ["openid", "offline_access"],
            redirectUri: window.location.protocol + '//' + window.location.host,
            extraQueryParameters: { from: window.location.pathname }
        };
        await this.loginRedirect(options);
    }

    /**
     * attempt to login with popup method
     * @param options
     */
    private async loginPopup(options: msal.AuthorizationUrlRequest) {
        if (!this.isInit)
            throw new AuthNotInitError();
        const loginResponse: msal.AuthenticationResult = await this.client!.loginPopup(options);
        this.handleResponse(loginResponse);
    }

    /**
     * attempt to lgoin with redirect
     * @param options
     */
    private async loginRedirect(options: msal.AuthorizationUrlRequest) {
        if (!this.isInit)
            throw new AuthNotInitError();
        await this.client!.loginRedirect(options);
    }

    /**
     * logout the user
     */
    async logout() {
        if (!this.isInit)
            throw new AuthNotInitError();
        this.clearAccountHint();
        const logoutRequest = {
            //account: this._account,
            postLogoutRedirectUri: window.location.protocol + '//' + window.location.host
        }
        await this.client!.logout(logoutRequest);
    }

    /**
     * Initialize the module after a redirect or if a session already exist
     */
    public async loadModule() {
        if (!this.isInit)
            throw new AuthNotInitError();
        const response = await this.client!.handleRedirectPromise();
        this.handleResponse(response);
    }

    /**
     * create an account object
     * @param response
     */
    private handleResponse(response: msal.AuthenticationResult | null) {
        if (response != null) {
            //back from a redirect login
            this._account = response.account
        } else {
            this._account = this.selectAccount();

        }
        this.saveAccountHint(this._account);
    }

    private saveAccountHint(account?: msal.AccountInfo) {
        if (account)
            localStorage.setItem(this.STORAGE_KEY, account.username);
    }

    private loadAccountHint(): string | undefined {
        const hint = localStorage.getItem(this.STORAGE_KEY);
        return hint ? hint : undefined
    }

    private clearAccountHint() {
        localStorage.removeItem(this.STORAGE_KEY);
    }

    /**
     * Get the correct account to signin, if there are multiple accounts select the first one
     */
    private selectAccount(): msal.AccountInfo | undefined {
        if (!this.isInit)
            throw new AuthNotInitError();

        const accounts: msal.AccountInfo[] = this.client!.getAllAccounts();
        if (accounts.length > 1) {
            console.log("Multiple Accounts found, selected first one")
            return accounts[0];
        } else if (accounts.length === 1) {
            return accounts[0];
        }
        else {
            console.info("No account found!");
            return undefined;
        }
    }

    /**
     * attempt to perform a silent login
     */
    public async attemptSilent(): Promise<msal.AccountInfo | undefined> {
        if (!this.isInit)
            throw new AuthNotInitError();
        try {
            const loginHint: string | undefined = this.loadAccountHint();
            if (loginHint === undefined) {
                console.info("No session found");
                return;
            }
            const loginResponse = await this.client!.ssoSilent({ prompt: 'none', loginHint: this.loadAccountHint() });
            this.handleResponse(loginResponse);
            return this._account;
        } catch (err) {
            if (err instanceof msal.InteractionRequiredAuthError) {
                //active login is required
                console.info("Silent SSO fail, fallback to interactive")
                //this.login()
            } else {
                //console.error(err)
                console.info("Unable to complete Silent SSO");
                this.clearAccountHint();
            }

        }
    }

    async acquireToken(options: msal.SilentRequest): Promise<msal.AuthenticationResult | undefined> {
        if (!this.isInit)
            throw new AuthNotInitError();
        try {
            const response = await this.client!.acquireTokenSilent(options);
            if (response == null)
                return await this.acquireTokenInteractive(options);
            return response
        } catch (err) {
            if (err instanceof msal.InteractionRequiredAuthError) {
                return await this.acquireTokenInteractive(options);
            }
            else {
                throw err;
            }
        }
    }

    private async acquireTokenInteractive(options: msal.SilentRequest): Promise<msal.AuthenticationResult | undefined> {
        if (this.isIE)
            await this.acquireTokenRedirect(options);
        else
            return await this.acquireTokenPopup(options);
    }

    private async acquireTokenPopup(options: msal.SilentRequest): Promise<msal.AuthenticationResult> {
        if (!this.isInit)
            throw new AuthNotInitError();
        return await this.client!.acquireTokenPopup(options);
    }

    private async acquireTokenRedirect(options: msal.SilentRequest): Promise<void> {
        if (!this.isInit)
            throw new AuthNotInitError();
        await this.client!.acquireTokenRedirect(options);
    }

    public async getIdToken() {
        if (!this.isInit)
            throw new AuthNotInitError();
        const options: msal.SilentRequest = {
            scopes: ["openid"],
            account: this._account!,
        }
        const token = await this.acquireToken(options);
        return { idToken: token!.idToken, idTokenClaims: token!.idTokenClaims };
    }

    public getAccount(): msal.AccountInfo | undefined {
        return this._account;
    }

    public isAuthenticated(): boolean {
        return this._account ? true : false
    }
}

class RAISEAuthService extends AuthService {
    public async getApiToken(): Promise<string> {
        if (!this.isInit)
            throw new AuthNotInitError();
        const options: msal.SilentRequest = {
            scopes: ["api://backend-raise/Admin.All"],
            account: this._account!,
        }
        const token = await this.acquireToken(options);
        return token!.accessToken;
    }
}

//create the service wihtout initialization
const authService = new RAISEAuthService(__APP_CONFIG.msal);

export default authService;