import { callAuthApi } from "app/services/api";
import { REQUEST_BASE_URL } from "app/store/appSaga";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  getSingleRequest,
  getRequestsError,
  getSingleRequestSuccess,
  deleteRequest,
  deleteRequestSuccess,
  deleteRequestError,
  completeRequest,
  completeRequestSuccess,
  completeRequestError,
} from "./requestsSlice";

function* retrieveSingleRequest(params: any) {
  const requestCode = params.payload.requestCode;
  const activeRole = params.payload.activeRole;
  try {
    const response = yield call(
      callAuthApi,
      `${REQUEST_BASE_URL}/${requestCode}?active_role=${activeRole}`,
      "get"
    );
    if (response && response.status === 200) {
      yield put(getSingleRequestSuccess(response.data));
    }
  } catch (error) {
    yield put(getRequestsError(error));
  }
}

function* deleteSingleRequest(params: any) {
  const requestCode = params.payload;
  try {
    const response = yield call(
      callAuthApi,
      `${REQUEST_BASE_URL}/${requestCode}`,
      "delete"
    );
    if (response && response.status === 200) {
      yield put(deleteRequestSuccess());
    }
  } catch (error) {
    yield put(deleteRequestError(error));
  }
}

function* completeSingleRequest(params: any) {
  const requestCode = params.payload;
  try {
    const response = yield call(
      callAuthApi,
      `${REQUEST_BASE_URL}/${requestCode}/complete`,
      "put"
    );
    if (response && response.status === 200) {
      yield put(completeRequestSuccess());
    }
  } catch (error) {
    yield put(completeRequestError(error));
  }
}

/**
 * SAGA event processor
 */
export default function* mainEventProcessor() {
  yield takeLatest(getSingleRequest, retrieveSingleRequest);
  yield takeLatest(deleteRequest, deleteSingleRequest);
  yield takeLatest(completeRequest, completeSingleRequest);
}
