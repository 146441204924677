import { RootState } from "app/store";
import React, { PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthFailed from "./AuthFailed";
import * as ABB from "@abb/abb-common-ux-react";
import { loginStart } from "app/auth/authSlice";

export default function AuthRequiredPage({ children }: PropsWithChildren<{}>) {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  if (auth.account)
    //user is authenticated
    return <>{children}</>;
  else {
    if (auth.error)
      //authentication failed
      return <AuthFailed error={auth.error} />;
    else {
      //start the login process
      if (!auth.isLoading) {
        dispatch(loginStart());
      }

      return (
        <ABB.LoadingIndicator
          type="radial"
          determinate={false}
          color="red"
          sizeClass="large"
          style={{
            margin: "0 auto",
          }}
        />
      );
    }
  }
}
