import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga'
import { createAction } from '@reduxjs/toolkit';

import authReducer from '../auth/authSlice';
import requestsReducer from '../requests/requestsSlice';
import userReducer from '../user/userSlice';
import mainSaga from "./appSaga";

//Action ammetted during app init
export const appInitAction = createAction("APP_INIT");
export type AppInitAction = ReturnType<typeof appInitAction>

//saga middleware
const sagaMiddleWare = createSagaMiddleware();

//reducers
const reducers = combineReducers({
  auth: authReducer,
  requests: requestsReducer,
  user: userReducer,
})

//create store
const store = configureStore({
  reducer: reducers,
  devTools: true,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      //disable thunk (use SAGA)
      thunk: false,
      //disable serializeable check to use redux-persist
      serializableCheck: false
    }).concat(sagaMiddleWare)
  }
})

//run main saga event loop
sagaMiddleWare.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>
export default store;