import { callAuthApi } from "app/services/api";
import { API_BASE_URL } from "app/store/appSaga";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  getUser,
  getUserError,
  getUserSuccess,
  getUserUnauthorized,
} from "./userSlice";

function* retrieveUser() {
  try {
    const response = yield call(callAuthApi, `${API_BASE_URL}/user`, "get");
    if (response && response.status === 200) {
      const user = response.data;

      if (user.is_admin) {
        user.active_role = "admin";
      }
      if (user.is_service_expert) {
        user.active_role = "service_expert";
      }
      yield put(getUserSuccess(user));
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      yield put(getUserUnauthorized());
    } else {
      yield put(getUserError(error.message));
    }
  }
}

/**
 * SAGA event processor
 */
export default function* mainEventProcessor() {
  yield takeLatest(getUser, retrieveUser);
}
