import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountInfo } from "@azure/msal-browser";

type UserInfo = {
    email: string,
    name: string,
}
interface SliceState {
    isLoading: boolean
    account?: AccountInfo,
    error?: string
}

const initState: SliceState = {
    isLoading: false,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initState,
    reducers: {
        loginSilentStart: (state) => {
            state.isLoading = true
            state.account = undefined
            state.error = undefined
        },
        loginStart: (state) => {
            state.isLoading = true
            state.account = undefined
            state.error = undefined
        },
        loginSuccess: (state, action: PayloadAction<{ account: AccountInfo, user: UserInfo }>) => {
            state.isLoading = false
            state.account = action.payload.account
            state.error = undefined;
        },
        loginError: {
            reducer: (state, action: PayloadAction<string>) => {
                state.isLoading = false
                state.error = action.payload;
                state.account = undefined;
            },
            prepare: (error: Error) => {
                return {
                    payload: error.message,
                    meta: {
                        error: true
                    }
                }
            }
        },
        logout: (state) => {
            state.account = undefined
        },
        noLogin: (state) => {
            state.isLoading = false;
            state.error = undefined;
            state.account = undefined;
        }
    }
});

export const { loginSuccess, loginStart, loginSilentStart, loginError, logout, noLogin } = authSlice.actions;
export default authSlice.reducer;