import { AccountInfo } from "@azure/msal-browser";
import { call, put, takeLatest } from "redux-saga/effects";
import { AppInitAction, appInitAction } from "../store";
import authService from "./authService";
import {
  loginError,
  loginSilentStart,
  loginStart,
  loginSuccess,
  /*logout as logoutAction,*/ noLogin,
} from "./authSlice";

/*
 * Handle login event from UI
 */
function* login() {
  //start login prosess event
  try {
    //start login on auth service
    yield call([authService, authService.login]);
    //save account on redux
    yield setAccount();
  } catch (error) {
    yield put(loginError(error));
  }
}

/**
 * APP INIT
 */
function* appInit(action: AppInitAction) {
  //start login prosess event
  yield put(loginSilentStart());
  try {
    //check if coming from a redirect
    yield call([authService, authService.loadModule]);
    //try to login silent if a session already exist
    // disable silent because the app is too slow
    //yield call([authService, authService.attemptSilent]);
    //save account on redux
    yield setAccount();
  } catch (error) {
    console.error(error);
    yield put(loginError(error));
  }
}

/**
 * Logout
 */
/*function* logout() {
    yield call([authService, authService.logout]);
}*/

function* setAccount() {
  //get account
  const account: AccountInfo | undefined = yield call([
    authService,
    authService.getAccount,
  ]);
  //sucess logged in
  if (account) {
    const idToken = yield call([authService, authService.getIdToken]);
    yield put(
      loginSuccess({
        account: account,
        user: {
          email: idToken.idTokenClaims.preferred_username,
          name: idToken.idTokenClaims.name,
        },
      })
    );
  }
  //no login
  else yield put(noLogin());
}

/**
 * SAGA event processor
 */
export default function* mainEventProcessor() {
  yield takeLatest(loginStart, login);
  yield takeLatest(appInitAction, appInit);
  //yield takeLatest(logoutAction,logout)
}
