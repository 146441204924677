import { loginStart } from 'app/auth/authSlice';
import React from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function AuthFailed(props: { error: string }) {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className="authExternalContainer">
            <div className="card col-md-6">
                <div className="card-body">
                    <h5 className="card-title">
                        Access failed
                    </h5>
                    <h6 className="card-subtitle mb-2 text-muted">
                        An error occurred during the login process
                    </h6>
                    <div className="card-text">
                        <p><small>{props.error}</small></p>
                        <button className="btn btn-primary" onClick={() => dispatch(loginStart())}>Login again</button>
                        <button className="btn btn-link" onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
