import * as ABB from "@abb/abb-common-ux-react";
import {
  getServiceExperts,
  getServiceExpertsError,
  getServiceExpertsSuccess,
  getSingleRequest,
  RaiseRequestEdit,
  RAISERequestIn,
  ServiceExpert,
  updateRequest,
  updateRequestError,
  updateRequestSuccess,
} from "app/requests/requestsSlice";
import { callAuthApi } from "app/services/api";
import { RootState } from "app/store";
import { API_BASE_URL, REQUEST_BASE_URL } from "app/store/appSaga";
import { Field, FieldProps, Form, Formik } from "formik";
import moment from "moment";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Redirect, useParams } from "react-router-dom";

const FormikFieldInput = (props: any) => {
  const styleProp: any = {
    width: "100%",
  };
  if (props.disabled === true) {
    styleProp["backgroundColor"] = "#f5f5f5";
    styleProp["borderColor"] = "#dbdbdb";
    styleProp["color"] = "#bababa";
  }
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <input
          key={field.name}
          type={props.type}
          id={field.name}
          placeholder={""}
          value={field.value || ""}
          onChange={form.handleChange}
          disabled={props.disabled}
          style={styleProp}
        />
      )}
    </Field>
  );
};

interface FormikFieldABBInputProps {
  name: string;
  type:
    | "color"
    | "date"
    | "email"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "textarea"
    | "time"
    | "url"
    | "week";
  text: string;
  disabled: boolean;
}

const FormikFieldABBInput = (props: FormikFieldABBInputProps) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => (
        <ABB.Input
          key={field.name}
          dataType={props.type}
          value={field.value}
          id={field.name}
          placeholder={""}
          onValueChange={(e) => {
            form.setFieldValue(field.name, e);
          }}
          disabled={props.disabled}
          label={props.text}
        />
      )}
    </Field>
  );
};

type FormikFieldABBInputDropdownProps = PropsWithChildren<{
  name: string;
  text: string;
  disabled: boolean;
}>;

const FormikFieldABBInputDropdown = (
  props: FormikFieldABBInputDropdownProps
) => {
  return (
    <Field name={props.name}>
      {({ field, form }: FieldProps) => {
        return (
          <ABB.Dropdown
            label={props.text}
            placeholder={""}
            value={[{ value: field.value ? field.value.toString() : "" }]}
            onChange={(val) => form.setFieldValue(field.name, val[0].value)}
            disabled={props.disabled}
          >
            {props.children}
          </ABB.Dropdown>
        );
      }}
    </Field>
  );
};

const RequestEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const requestsState = useSelector((state: RootState) => state.requests);
  const { requestCode, activeRole } = useParams<{
    requestCode: string;
    activeRole: string;
  }>();
  const [raiseRequest, setRaiseRequest] = useState<RAISERequestIn>(
    {} as RAISERequestIn
  );
  const [serviceExperts, setServiceExperts] = useState<ServiceExpert[]>([]);
  const { isLoading, singleRequest, isLoadingAsyncData, isUpdating, error } =
    requestsState;

  useEffect(() => {
    if (requestCode && activeRole) {
      dispatch(getSingleRequest({ requestCode, activeRole }));
    }
  }, [activeRole, dispatch, requestCode]);

  useEffect(() => {
    dispatch(getServiceExperts());
    callAuthApi(`${API_BASE_URL}/service-expert/${requestCode}`, "get")
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(getServiceExpertsSuccess());
          setServiceExperts(response.data);
        }
      })
      .catch((error) => {
        dispatch(getServiceExpertsError(error));
      });
  }, [dispatch, requestCode]);

  useEffect(() => {
    if (singleRequest) {
      const request = {
        request_code: singleRequest.request_code,
        requestor: singleRequest.requestor,
        requestor_phone: singleRequest.requestor_phone,
        user_preferred_datetime: moment(
          singleRequest.user_preferred_datetime
        ).format(moment.HTML5_FMT.DATETIME_LOCAL),
        product_type: singleRequest.product_type.code,
        country: singleRequest.country.code,
        assistance_type: singleRequest.assistance_type.code,
        device: singleRequest.device.code,
        time_slot: singleRequest.time_slot
          ? singleRequest.time_slot.code
          : undefined,
        note: singleRequest.note ? singleRequest.note : undefined,
        state: singleRequest.state.code,
        paid: singleRequest.paid.toString(),
        service_expert_email: singleRequest.service_expert
          ? singleRequest.service_expert.user_email
          : undefined,
        created_datetime: singleRequest.created_datetime,
        modified_datetime: singleRequest.modified_datetime,
      };
      setRaiseRequest(request);
    }
  }, [singleRequest]);

  if (isLoading || isLoadingAsyncData) {
    return (
      <ABB.LoadingIndicator
        type="bar"
        sizeClass="medium"
        color="red"
        determinate={false}
      />
    );
  }

  if (activeRole !== "service_expert" && activeRole !== "admin") {
    return <Redirect to="/requests" />;
  }
  if (!singleRequest) return <div>Not found</div>;

  return (
    <div className="container">
      <Formik
        enableReinitialize
        initialValues={raiseRequest}
        onSubmit={(values) => {
          const formattedRequest: RaiseRequestEdit = {
            request_code: values.request_code,
            user_preferred_datetime: moment(
              values.user_preferred_datetime
            ).format(),
            note: values.note,
            paid: values.paid,
            service_expert_email: values.service_expert_email,
          };

          dispatch(updateRequest());
          return callAuthApi(`${REQUEST_BASE_URL}`, "put", formattedRequest)
            .then((response) => {
              if (response && response.status === 200) {
                dispatch(updateRequestSuccess());
                history.push("/requests");
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                dispatch(updateRequestError(error.response.data.detail));
              } else {
                dispatch(updateRequestError(error.message));
              }
            });
        }}
      >
        {({ submitForm }) => (
          <Form>
            <FormikFieldABBInput
              name="request_code"
              type="text"
              text="Request code"
              disabled={true}
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="requestor"
              type="email"
              text="Requestor"
              disabled={true}
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="requestor_phone"
              type="text"
              text="Requestor phone"
              disabled={true}
            ></FormikFieldABBInput>
            <div className="form-group">
              <label htmlFor="user_preferred_datetime">
                User preferred datetime
              </label>
              <FormikFieldInput
                type="datetime-local"
                name="user_preferred_datetime"
                text="Date"
                disabled={
                  raiseRequest.state === "COMPLETED" ||
                  raiseRequest.state === "CANCELED"
                }
              />
            </div>
            <FormikFieldABBInput
              name="product_type"
              type="text"
              text="Product type"
              disabled={true}
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="country"
              type="text"
              text="Country"
              disabled={true}
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="assistance_type"
              type="text"
              text="Assistance type"
              disabled={true}
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="device"
              type="text"
              text="Device"
              disabled={true}
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="time_slot"
              type="text"
              text="Time slot"
              disabled={true}
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="note"
              type="textarea"
              disabled={false}
              text="Note"
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="state"
              type="text"
              disabled={true}
              text="State"
            ></FormikFieldABBInput>
            <FormikFieldABBInputDropdown
              name="paid"
              text="Paid"
              disabled={raiseRequest.state === "CANCELED"}
            >
              <ABB.DropdownOption key="false" label="No" value="false" />
              <ABB.DropdownOption key="true" label="Yes" value="true" />
            </FormikFieldABBInputDropdown>
            <FormikFieldABBInputDropdown
              name="service_expert_email"
              text="Service expert"
              disabled={
                activeRole === "service_expert" ||
                raiseRequest.state === "COMPLETED" ||
                raiseRequest.state === "CANCELED"
              }
            >
              {serviceExperts.map((c: ServiceExpert) => (
                <ABB.DropdownOption
                  key={c.user_email}
                  label={c.user_email}
                  value={c.user_email}
                />
              ))}
            </FormikFieldABBInputDropdown>
            <FormikFieldABBInput
              name="created_datetime"
              type="text"
              disabled={true}
              text="Created datetime"
            ></FormikFieldABBInput>
            <FormikFieldABBInput
              name="modified_datetime"
              type="text"
              disabled={true}
              text="Modified datetime"
            ></FormikFieldABBInput>
            <br />
            <ABB.Button
              type="primary-blue"
              disabled={false}
              text="Save"
              onClick={submitForm}
              isLoading={isUpdating}
              style={{
                marginBottom: "20px",
              }}
            />
            {error && (
              <>
                <div className="alert alert-danger mt-2" role="alert">
                  {error}
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RequestEdit;
