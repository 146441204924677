import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RAISEUser {
  user_email: string;
  is_service_expert: boolean;
  is_admin: boolean;
  is_global: boolean;
  product_types: string[];
  countries: string[];
  acty_link: string;
  active_role: "service_expert" | "admin";
}

interface RequestSliceState {
  isLoading: boolean;
  isNotAuthorized: boolean;
  user: RAISEUser;
  error?: string;
}

const initState: RequestSliceState = {
  isLoading: false,
  isNotAuthorized: false,
  user: {} as RAISEUser,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    getUser: (state) => {
      state.isLoading = true;
    },
    getUserSuccess: (state, action: PayloadAction<RAISEUser>) => {
      state.isLoading = false;
      state.user = action.payload;
    },
    getUserUnauthorized: (state) => {
      state.isLoading = false;
      state.isNotAuthorized = true;
      state.user = {} as RAISEUser;
    },
    getUserError: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.user = {} as RAISEUser;
      state.error = action.payload.toString();
    },
    setActiveRole: (
      state,
      action: PayloadAction<"service_expert" | "admin">
    ) => {
      state.user.active_role = action.payload;
    },
  },
});

export const {
  getUser,
  getUserSuccess,
  getUserError,
  getUserUnauthorized,
  setActiveRole,
} = userSlice.actions;
export default userSlice.reducer;
